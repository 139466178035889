var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"30px"}},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"no-wrap":""}}),_c('validation-observer',{ref:"formDDA"},[_c('b-form',[_c('app-collapse',{attrs:{"id":"collapse-besoin","type":"margin"}},[_c('b-row',[_c('b-col',[_c('h2',{staticClass:"text-center text-uppercase mb-3"},[_vm._v("FORMULAIRE PARCOURS FORMATION DDA")])])],1),_c('app-collapse-item',{ref:"tab-collapse-item-1",attrs:{"title":"","is-visible":true}},[(!_vm.form_is_filled)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Qui suivra cette formation ?")]),_c('validation-provider',{attrs:{"name":"inscrit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticStyle:{"margin-left":"100px"},attrs:{"options":_vm.optionsInscrit,"name":"radio-inscrit"},model:{value:(_vm.formDDA.inscrit),callback:function ($$v) {_vm.$set(_vm.formDDA, "inscrit", $$v)},expression:"formDDA.inscrit"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])]}}],null,false,279464504)})],1)])],1)],1),(_vm.users_cabinet[0].courtier_user.length>0)?_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"interlocuteur"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Quel interlocuteur de mon cabinet ?")]),_c('v-select',{staticClass:"flex-grow-1",staticStyle:{"margin-left":"100px"},attrs:{"rules":"required","close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.users_cabinet_options,"input-id":"beneficier","label":"nom","placeholder":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.formDDA.interlocuteur),callback:function ($$v) {_vm.$set(_vm.formDDA, "interlocuteur", $$v)},expression:"formDDA.interlocuteur"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune createur disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])],1)])]}}],null,false,2183065787)})],1)],1):_vm._e(),(_vm.otherPersonBlock)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Quel est son adresse mail ?")]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"margin-left":"100px"},attrs:{"id":"email","placeholder":"Email","type":"email"},model:{value:(_vm.formDDA.email),callback:function ($$v) {_vm.$set(_vm.formDDA, "email", $$v)},expression:"formDDA.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])]}}],null,false,643893230)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Quel est son nom ?")]),_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text-capitalize",staticStyle:{"margin-left":"100px"},attrs:{"id":"nom","autocomplete":"nope","type":"text","placeholder":"Nom"},model:{value:(_vm.formDDA.nom),callback:function ($$v) {_vm.$set(_vm.formDDA, "nom", $$v)},expression:"formDDA.nom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])]}}],null,false,2458408767)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Quel est son prénom ?")]),_c('validation-provider',{attrs:{"name":"prénom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text-capitalize",staticStyle:{"margin-left":"100px"},attrs:{"id":"prenom","autocomplete":"nope","type":"text","placeholder":"Prénom"},model:{value:(_vm.formDDA.prenom),callback:function ($$v) {_vm.$set(_vm.formDDA, "prenom", $$v)},expression:"formDDA.prenom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])]}}],null,false,107437268)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Quelle est son organisation de rattachement ?")]),_c('validation-provider',{attrs:{"name":"organisation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text-capitalize",staticStyle:{"margin-left":"100px"},attrs:{"id":"organisation","autocomplete":"nope","type":"text","placeholder":"Dénomination du cabinet"},model:{value:(_vm.formDDA.organisation),callback:function ($$v) {_vm.$set(_vm.formDDA, "organisation", $$v)},expression:"formDDA.organisation"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])]}}],null,false,1862992143)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Quel est son statut juridique ?")]),_c('validation-provider',{attrs:{"name":"statut juridique","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text-capitalize",staticStyle:{"margin-left":"100px"},attrs:{"id":"organisation","autocomplete":"nope","type":"text","placeholder":"Courtier, Dirigeant, Salarié…"},model:{value:(_vm.formDDA.statut_juridique),callback:function ($$v) {_vm.$set(_vm.formDDA, "statut_juridique", $$v)},expression:"formDDA.statut_juridique"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])]}}],null,false,686793536)})],1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"float-left pr-4 mb-1"},[_vm._v("Quel parcours a été choisi ?")]),_c('validation-provider',{attrs:{"name":"parcours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticStyle:{"margin-left":"100px"},attrs:{"options":_vm.optionsParcours,"name":"radio-parcour"},model:{value:(_vm.formDDA.parcours),callback:function ($$v) {_vm.$set(_vm.formDDA, "parcours", $$v)},expression:"formDDA.parcours"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v("Champ obligatoire")])]}}],null,false,1284566376)})],1)])],1)],1),_c('b-button',{attrs:{"disabled":_vm.isDisabled,"variant":"primary","size":"md"},on:{"click":_vm.sendCredentials}},[_c('span',{staticClass:"align-middle"},[_vm._v("Valider le formulaire")])])],1):_c('div',[_c('h5',{staticClass:"text-left  mb-2"},[_vm._v("Merci ! Nous avons pris en compte vos choix. Un mail d’accès à la formation sera envoyé dans les prochains jours à la personne concernée.")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }