<template>
  <div style="margin-top:30px">
      <b-overlay :show="showLoading" no-wrap />
      <validation-observer 

      ref="formDDA">
      <b-form
        >
        <app-collapse
          id="collapse-besoin"
          type="margin"
        >
        <b-row>
          <b-col>
            <h2 class="text-center text-uppercase mb-3">FORMULAIRE PARCOURS FORMATION DDA</h2>
          </b-col>
        </b-row>
        <app-collapse-item ref="tab-collapse-item-1" title="" :is-visible="true" >
          <div v-if="!form_is_filled">

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <div  class="d-flex flex-column"> 

                    <label class="float-left pr-4 mb-1">Qui suivra cette formation ?</label>
                    <validation-provider #default="{ errors }" name="inscrit" rules="required">
                      <b-form-radio-group style="margin-left:100px;" v-model="formDDA.inscrit" :options="optionsInscrit" name="radio-inscrit" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row v-if="users_cabinet[0].courtier_user.length>0">
              <b-col md="3">
                <validation-provider #default="{ errors }" name="interlocuteur">
          <b-form-group  :state="errors.length > 0 ? false : null" >
            <div  class="d-flex flex-column"> 

            <label class="float-left pr-4 mb-1">Quel interlocuteur de mon cabinet ?</label>

            <v-select style="margin-left:100px;" v-model="formDDA.interlocuteur"  rules="required" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="users_cabinet_options" class="flex-grow-1" input-id="beneficier" label="nom" placeholder="">
              <template #option="{ avatar, nom }">
                <b-avatar :src="avatar" size="sm" />
                <span class="ml-50"> {{ nom }}</span>
              </template>

              <template #selected-option="{ avatar, nom }">
                <b-avatar :src="avatar" class="border border-white" size="sm" />
                <span class="ml-50"> {{ nom }}</span>
              </template>
              <div slot="no-options">Aucune createur disponible.</div>
            </v-select>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </validation-provider>
              </b-col>

            </b-row>
            <div v-if="otherPersonBlock">
              <b-row>
              <b-col md="3">
              <b-form-group>
                <label class="float-left pr-4 mb-1">Quel est son adresse mail ?</label>

                <validation-provider #default="{ errors }" name="email" rules="required|email">
                  <b-form-input id="email" v-model="formDDA.email" placeholder="Email" type="email" style="margin-left:100px;"  />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
                </validation-provider>
               </b-form-group>
            </b-col>
              </b-row>
              <b-row>

              <b-col md="3">
                <b-form-group>
                  <label class="float-left pr-4 mb-1">Quel est son nom ?</label>
                  <validation-provider #default="{ errors }" name="nom" rules="required">
                    <b-form-input id="nom"
                    v-model="formDDA.nom"
                    autocomplete="nope"
                    type="text"
                    placeholder="Nom"
                    style="margin-left:100px;"
                    class="text-capitalize" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              </b-row>
              <b-row>

              <b-col md="3">
                <b-form-group>
                  <label class="float-left pr-4 mb-1">Quel est son prénom ?</label>
                  <validation-provider #default="{ errors }" name="prénom" rules="required">
                    <b-form-input id="prenom"
                    v-model="formDDA.prenom"
                    autocomplete="nope"
                    type="text"
                    placeholder="Prénom"
                    style="margin-left:100px;"
                    class="text-capitalize" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              </b-row>
              <b-row>

              <b-col md="3">
                <b-form-group>
                  <label class="float-left pr-4 mb-1">Quelle est son organisation de rattachement ?</label>
                  <validation-provider #default="{ errors }" name="organisation" rules="required">
                    <b-form-input id="organisation"
                    v-model="formDDA.organisation"
                    autocomplete="nope"
                    type="text"
                    placeholder="Dénomination du cabinet"
                    style="margin-left:100px;"
                    class="text-capitalize" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              </b-row>
              <b-row>

              <b-col md="3">
                <b-form-group>
                  <label class="float-left pr-4 mb-1">Quel est son statut juridique ?</label>
                  <validation-provider #default="{ errors }" name="statut juridique" rules="required">
                    <b-form-input id="organisation"
                    v-model="formDDA.statut_juridique"
                    autocomplete="nope"
                    type="text"
                    placeholder="Courtier, Dirigeant, Salarié…"
                    style="margin-left:100px;"
                    class="text-capitalize" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <div  class="d-flex flex-column"> 

                  <label class="float-left pr-4 mb-1">Quel parcours a été choisi ?</label>
                  <validation-provider #default="{ errors }" name="parcours" rules="required">
                    <b-form-radio-group style="margin-left:100px;" v-model="formDDA.parcours" :options="optionsParcours" name="radio-parcour" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Champ obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                  </div>
                </b-form-group>
              </b-col>

            </b-row>
            
            <b-button @click="sendCredentials" :disabled="isDisabled"  variant="primary" size="md">
            <span class="align-middle">Valider le formulaire</span>
          </b-button>
          </div>
          <div v-else>
            <h5 class="text-left  mb-2">Merci ! Nous avons pris en compte vos choix. Un mail d’accès à la formation sera envoyé dans les prochains jours à la personne concernée.</h5>
            <!-- <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          De retour à l'accueil
        </b-button> -->
          </div>
            </app-collapse-item>
        </app-collapse>

    </b-form>
      </validation-observer>
      </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { rules } from '@core/libs/validations'
import { required,email } from '@validations'

import {
  BOverlay,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormValidFeedback,
  BAlert,
  VBTooltip,
  VBPopover,
  BFormSelectOption,
  BAvatar
} from "bootstrap-vue";
export default {
    components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormValidFeedback,
    BOverlay,
    BAlert,
    BFormCheckboxGroup,
    BFormSelectOption,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    BAvatar
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [heightTransition],
  data(){
    return{

        showLoading:false,
        optionsInscrit:[
        { text: 'Moi', value: 'courtier' },
        { text: 'Une autre personne', value: 'autre' },
        ],
        optionsParcours:[
        { text: 'Réglementaire 2024', value: 'reglementaire' },
        { text: 'Assurances de personnes 2024', value: 'assurances_de_ personnes' },
        { text: 'IARD 2024', value: 'iard' },
        ],
        formDDA:{},
        otherPersonBlock:false,
        users_cabinet:[],
        users_cabinet_options:[],
        courtier_user:null,
        form_is_filled:false,
        isDisabled:false
        

    }
  },
  created(){
    localStorage.removeItem('url')
    this.checkAndValidateAccessToPage();
    
  },
  methods:{
    resetForm(){

    },
    sendCredentials(){
      this.$refs.formDDA.validate().then((success)=>{
        if(success){
          this.showLoading = true;
          this.isDisabled = true;
          let requestData = {
            beneficiaire_type:this.formDDA.inscrit,
            courtier_user_id:this.getBenificierCourtierUserId(),
            parcours:this.formDDA.parcours,
            expire_token_url:this.$route.params.expire_token_url
          }
          if(this.formDDA.inscrit == 'autre'){
            requestData.beneficiaire_email = this.formDDA.email;
            requestData.beneficiaire_nom = this.formDDA.nom;
            requestData.beneficiaire_prenom = this.formDDA.prenom;
            requestData.beneficiaire_organisation = this.formDDA.organisation;
            requestData.beneficiaire_statut_juridique = this.formDDA.statut_juridique;
          } 
          console.log(requestData)

          this.$http.post('courtier/store-information-pack-formation-dda',requestData).then((res)=>{
            console.log(res.data.data)
            this.showLoading = false;
            this.form_is_filled = true
          }).catch((err)=>{
              console.error(err);
              this.isDisabled = false
              this.showLoading=false
            })

        }
      })
    },
    async loadUsersCabinet(){
      this.showLoading=true
       await this.$http.get(`collaborateur/load_cabinet_users_formation_dda/${this.courtier_user.courtier_id}`).then((res)=>{
        // console.log(res.data.data)
        this.users_cabinet = res.data.data
        console.log("users_cabinet",this.users_cabinet)

        if(this.users_cabinet[0].courtier_user.length>0){
          this.fillUsersOptionsSelect(this.users_cabinet)
          this.optionsInscrit.push({ text: 'Un autre utilisateur de BubbleIn', value: 'interlocuteur' })
        }
        this.showLoading=false
       }).catch((err)=>{
        console.error(err);
        this.showLoading=false
       })
    },
    fillUsersOptionsSelect(data){
      data[0].courtier_user.forEach(cu => {
        this.users_cabinet_options.push({
          id:cu.id,
          user_id:cu.user_id,
          nom:cu.user.personne_physique.prenom + ' ' + cu.user.personne_physique.nom,
          type:cu.user.personne_physique.type
        })
      });
      console.log("users_cabinet_options",this.users_cabinet_options)

    },
    getBenificierCourtierUserId(){
      switch (this.formDDA.inscrit) {
        case 'interlocuteur':
          return this.formDDA.interlocuteur.id
        case 'courtier':
          return this.courtier_user.id
        default:
          return null;
      }
    },
    async checkAndValidateAccessToPage(){
      const data = {
        cryptedParam : this.$route.params.cu,
        expire_token_url:this.$route.params.expire_token_url
      };
      this.showLoading = true
      await this.$http.post('collaborateur/check-validate-access-to-formation-dda-form',data).then((res)=>{
        if(res.data.data.access){
          this.courtier_user = res.data.data.courtier_user
          this.loadUsersCabinet();
      this.showLoading = false

        }else{

          this.$router.push({name:"page-not-authorized"})
        }
      }).catch((err)=>{
        console.error(err);
      this.showLoading = false

       })
    }
  },
  watch:{
    'formDDA.inscrit':{
      handler(val){
        if(val == 'autre'){
          this.otherPersonBlock = true
          // this.users_cabinet=[]
      // this.users_cabinet_options = []

        }else if (val=='interlocuteur'){
          // this.loadUsersCabinet();
          this.otherPersonBlock = false
        }else{
          this.otherPersonBlock = false
          // this.users_cabinet=[]
      // this.users_cabinet_options = []

        }
      }
    }
  }

}
</script>
<style lang="scss">

@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
@import '@core/scss/vue/libs/quill.scss';

.block-cylindre .custom-checkbox .custom-control-label::before,
.block-cylindre .custom-checkbox .custom-control-label::after {
    top: 0.7rem;
}

.label-cylindre {
  margin-top: 0.7rem;
  min-width: 125px;
}

.title-custom-wizard{
    color: #4d25bc ;
    margin-bottom: 0px;
}
.text_info
{
  display: block;
  margin-top: 5px;
  color: #4d25bc ;
  font-style: italic;
  font-size:0.9rem;
  font-weight: 600;
}
.signature-checkbox .custom-control-input[disabled] ~ .custom-control-label,.signature-checkbox .custom-control-input:disabled ~ .custom-control-label
{
  color:#5e5873;
}
.profil_checkbox .custom-checkbox.custom-control
{
  padding-bottom: 10px;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.min-w-150 {
  min-width: 150px
}

#collapse-besoin .card:has(div.d-block.invalid-feedback, input.is-invalid) span h4 {
    color: #ea5455;
}

.w-80px {
  width: 80px!important;
}

.w-100px {
  width: 100px!important;
}
</style>

<style lang="scss" scoped>
label{
    font-weight: 900;
}

.w-125 {
  width: 125px;
}
.w-190 {
  width: 190px!important;
}
.h-40p {
  height: 40px;
}
</style>